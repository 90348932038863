.client-card {
  box-shadow: 0 0 5px #e3e3e3;
  text-align: center;
  padding: 1rem;
  margin-bottom: 2rem;
}
.client-card img {
  height: 100px;
  object-fit: contain;
}
.client-card p {
    display: none;
  font-weight: bold;
}

.education-card{
  background-color: #ffffff;
    border-left: 2px solid var(--orange-color);
    padding: 20px;
    transition: all ease 0.5s;
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
    height: 100%;
    transform: translateY(0);
    transition: all .3s ease-out 0s;
}