.occupational-img {
  position: relative;
}
.occupational-img span {
  position: absolute;
  width: 70px;
  height: 70px;
  background: #fff;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.occ-card p{
    font-size: .9rem;
    text-align: justify;
}