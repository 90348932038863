.tp-about-us__main-thumb {
  margin-bottom: 100px;
  position: relative;
}
.tp-about-us__thumb-sm {
  position: absolute;
  right: -18px;
  bottom: -85px;
}
.tp-about-us__thumb-border {
  position: absolute;
  top: 60px;
  right: 20px;
  height: 355px;
  width: 290px;
  border: 4px solid var(--orange-color);
  z-index: -1;
}
.tp-about-us__main-thumb::after {
  position: absolute;
  height: 62px;
  width: 18px;
  background-color: var(--blue-color);
  content: "";
  left: 50px;
  bottom: -85px;
}



.oracle-partner-card{
    background-color: #ffffff;
    border-left: 2px solid var(--orange-color);
    padding: 20px;
    transition: all ease 0.5s;
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
    height: 100%;
    transform: translateY(0);
    transition: all .3s ease-out 0s;
}
.oracle-partner-card:hover{
    transform: translateY(-5px);
}
.oracle-partner-card h3{
    font-size: 1.4rem;
    font-weight: 600;
}
.oracle-partner-card p,
.contact-us-inner p{
    font-size: 0.9rem;
  font-weight: 500;
  color: #4f4f4f;
  text-align: justify;
}


@media (max-width: 991px) {
    .tp-about-us__main-thumb,
  .tp-about-us__thumb-sm {
    position: static;
  }
  .tp-about-us__main-thumb img {
    width: 100%;
    margin-bottom: 30px;
}
.tp-about-us__main-thumb::after{
    display: none;
}
.tp-about-us__thumb-border {
    border: none;
}
}
