.page-title-area {
  position: relative !important;
  z-index: 1;
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("./assets/inner-banner/story.jpg");
  background-position: top center;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 200px;
}
.child-inner {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("./assets/inner-banner/child-skills.jpeg");
  background-position-y: -200px;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.contact-inner {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("./assets/inner-banner/contact-banner.jpg");
  background-position-y: -100px;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.tech-inner {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("./assets/inner-banner/tech-banner.jpg");
  background-position-y: -50px;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.consul-inner {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("./assets/inner-banner/cunsol-banner.jpg");
  background-position-y: -100px;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.occu-inner {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("./assets/inner-banner/occup-banner.jpg");
  background-position-y: 0px;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.page-title-area::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: -1;
}

.page-title-content {
  text-align: center;
}

.page-title-content ul {
  padding-left: 0;
  list-style-type: none;
}

.page-title-content ul li,
.page-title-content ul h1 {
  color: #ffffff;
  display: inline-block;
  position: relative;
  font-weight: 400;
  font-size: 3.3rem;
  margin-left: 10px;
  margin-right: 10px;
}

.page-title-content ul li a {
  display: inline-block;
  color: #ffffff !important;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.page-title-content ul li::before {
  content: "|";
  position: absolute;
  right: -15px;
  top: 0;
}

.page-title-content ul li:last-child::before {
  display: none;
}

.swiper-horizontal {
  padding-bottom: 4rem !important;
}
.swiper-button-prev,
.swiper-button-next {
  color: #fff !important;
  border-radius: 50%;
  height: 40px !important;
  width: 40px !important;
  background: var(--orange-color) !important;
  top: var(--swiper-navigation-top-offset, 80%) !important;
}
.award-slider .swiper-button-prev,
.award-slider .swiper-button-next {
  top: var(--swiper-navigation-top-offset, 95%) !important;
}
.swiper-button-prev {
  left: 46% !important;
}
.swiper-button-next {
  right: 46% !important;
}
.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 1.5rem !important;
}
.swiper-pagination .swiper-pagination-bullet {
  background: transparent !important;
}
.react-multi-carousel-track {
  padding-bottom: 2.8rem !important;
}

.swiper-pagination .swiper-pagination-bullet {
  background-color: initial;
  border: 1px solid #fff;
  border-radius: 50%;
  width: 20px !important;
  height: 20px !important;
  outline: 0;
  position: relative;
  transition: 0.5s;
}
.swiper-pagination .swiper-pagination-bullet {
  border: 1px solid var(--orange-color);
  opacity: 1 !important;
  box-shadow: none !important;
}
.react-multi-carousel-dot button {
  border-color: var(--orange-color) !important;
}

.swiper-pagination .swiper-pagination-bullet:before {
  border-radius: 50%;
  content: "";
  height: 50%;
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: 0.3s;
  width: 50%;
}

.swiper-pagination
  .swiper-pagination-bullet.swiper-pagination-bullet-active:before {
  background-color: var(--blue-color);
}

.swiper-pagination
  .swiper-pagination-bullet.swiper-pagination-bullet-active:before {
  background-color: var(--orange-color) !important;
}


body .uwy.userway_p1 .userway_buttons_wrapper {
  top: -686px !important;
  bottom: 0 !important;
  left: 95% !important;
  transform: translate(-100%) !important;
}

@media (max-width: 1200px) {
  .swiper-button-prev {
    left: 45% !important;
  }
  .swiper-button-next {
    right: 45% !important;
  }
}
@media (max-width: 991px) {
  .page-title-content ul li {
    font-size: 1.3rem;
  }
  .page-title-area {
    height: auto;
    padding-top: 0.8rem;
  }
  .swiper-horizontal {
    padding-bottom: 3rem !important;
  }
  .award-slider {
    padding-bottom: 4rem !important;
  }
  .swiper-button-prev,
  .swiper-button-next {
    top: var(--swiper-navigation-top-offset, 94%) !important;
  }
  .swiper-button-prev {
    left: 42% !important;
  }
  .swiper-button-next {
    right: 42% !important;
  }
  .client-swiper .swiper-button-prev,
  .client-swiper .swiper-button-next {
    top: var(--swiper-navigation-top-offset, 88%) !important;
  }
  .client-swiper {
    padding-bottom: 2rem !important;
  }
  .swiper-wrapper {
    height: auto !important;
  }
  .footer {
    margin-top: 2rem;
  }
}
@media (max-width: 568px) {
  .page-title-content ul li,
  .page-title-content ul h1 {
    font-size: 2rem;
  }
  .swiper-horizontal,
  .award-slider {
    padding-bottom: 3rem !important;
  }

  .swiper-button-prev {
    left: 35% !important;
  }
  .swiper-button-next {
    right: 35% !important;
  }
  
body .uwy.userway_p1 .userway_buttons_wrapper {
  top: -686px !important;
  bottom: 0 !important;
  left: 86% !important;
  transform: translate(-100%) !important;
}
}
