.left-section {
  position: relative;
  background: linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)),
    url("../../assets/img/manzil-banner.png") bottom no-repeat;
  background-size: cover;
  /* background: #556b2f; */
  /* background: #009000; */
  /* background: #4cc319; */
  /* background: #adadad; */
  /* background: var(--orange-color); */
  padding: 1rem;
  padding-bottom: 1.3rem;
  padding-right: 0;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}
.left-section .default-btn {
  font-weight: 600;
}
.left-section .default-btn:hover {
  color: #fff !important;
}
/* .left-section .manzil-logo{
    background: #fff;
} */
.left-section img {
  width: 200px;
  height: 80px;
  object-fit: contain;
  /* background: #fff; */
  padding-left: 2rem;
}
.ls-content {
  /* background: rgba(0, 0, 0, 0.674); */
  background: #2951a1b6;
  padding: 1rem 2rem;
  margin-top: 13.3rem;
  /* width: 98%; */
  /* box-shadow: 0 0 10px #00000080;
    border-radius: 0 5px 5px 0; */
}
.ls-content h1 {
  /* color: var(--blue-color); */
}
.ls-content p {
  /* color: var(--orange-color); */
}

.ls-bottom ul {
  display: flex;
  list-style: none;
  padding-left: 2rem;
}
.ls-bottom ul li {
  margin-right: 0.6rem;
}
.ls-bottom ul li a {
  color: #fff;
  font-size: 1.8rem;
    padding: 8px;
  /* background: rgba(0, 0, 0, 0.8); */
  background: var(--blue-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  /* font-size: 1.1rem; */
  transition: 0.3s;
}
.ls-bottom ul li .w-icon {
  background: #25d366 !important;
}
.ls-bottom ul li .facebook-icon,
.facebook-icon {
  background: #1877f2 !important;
}
.ls-bottom ul li .insta-icon,
.insta-icon {
    
  /* background: #d6249f; */
  /* background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%) !important; */
  /* background: radial-gradient(circle at 33% 100%, #FED373 4%, #F15245 30%, #D92E7F 62%, #9B36B7 85%, #515ECF) !important; */
  /* background: linear-gradient(#400080, transparent),
    linear-gradient(200deg, #d047d1, #ff0000, #ffff00);
  background: radial-gradient(
      circle farthest-corner at 35% 90%,
      #fec564,
      transparent 50%
    ),
    radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%),
    radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%),
    radial-gradient(
      ellipse farthest-corner at 20% -50%,
      #5258cf,
      transparent 50%
    ),
    radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%),
    radial-gradient(
      ellipse farthest-corner at 60% -20%,
      #893dc2,
      transparent 50%
    ),
    radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent),
    linear-gradient(
      #6559ca,
      #bc318f 30%,
      #e33f5f 50%,
      #f77638 70%,
      #fec66d 100%
    ); */
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%) !important   ;
}

.ls-bottom ul li .linkedin-icon,
.linkedin-icon {
  background: #0A66C2 !important;
}

@media (max-width: 1523px) {
  .ls-content {
    margin-bottom: 1rem;
    margin-top: 16rem;
  }
  .left-section {
    height: auto;
  }
}
