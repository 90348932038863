.story-image {
  background: #e3e3e351 none repeat scroll 0 0;
  display: inline-block;
  margin-bottom: 20px;
  margin-left: 24px;
  width: 90%;
}
.story-image img {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  margin-left: -24px;
  position: relative;
  top: 20px;
  width: 100%;
}
.oracle-consul-card {
  margin: 0 1rem;
  height: 100%;
  background: var(--theme-color-white);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0px 16px 12px 0px rgba(0, 0, 0, 0.06);
}
.award-card {
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.06);
}

.award-card img {
  background: #e3e3e3;
  height: 250px;
  width: 100%;
  object-fit: contain;
}
.award-content {
  height: auto;
  padding: 1rem;
  border-top: 1px solid #e3e3e3b0;
  text-align: justify;
}
.award-content h5 {
  text-transform: capitalize;
  text-align: left;
}
.award-content p {
  font-size: 0.9rem;
}
.story-content p {
  font-size: 0.9rem;
}
.react-read-more-read-less,
.react-read-more-read-less-more {
  cursor: pointer;
  color: var(--orange-color);
}
@media (max-width: 568px) {
  .award-card {
    height: auto;
  }
  .story-image {
    margin: 0;
    width: 100%;
    background: none;
    padding-bottom: 2rem;
  }
  .story-image img {
    position: static;
    margin: 0;
  }
  p {
    text-align: justify;
  }
  .award-content {
    height: auto !important;
  }
}
