.right-cards .right-card > a{
    height: 100%;
    color: #fff ;
    position: relative;
}
.right-cards .right-card .rc-content{
    background: rgba(39, 39, 39, 0.774);
    padding: 1rem;
    height: 65%;
}
.right-cards .right-card > a .text-end{
    position: absolute;
    right: 10px;
    bottom: 10px;
}
.right-cards .right-card a svg {
  width: 35px;
  height: 35px;
  background: var(--orange-color);
  border-radius: 50%;
  padding: 5px;
  color: #fff;
  transition: 0.3s;
}
.right-cards .right-card a svg:hover {
  background-color: var(--blue-color);
}
.right-cards .right-card > a{

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  background-size: cover;
  color: #fff;
}
.right-cards .right-card:nth-child(1) > a {
  background-size: cover;
  background:
     /* linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), */ url("../../assets/img/child-development.png")
    top no-repeat;
    background-size: cover; 
}
.right-cards .right-card:nth-child(2) > a {
  background-size: cover;
  background:
     /* linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), */ url("../../assets/img/occupational-skills.png")
    top no-repeat;
    background-size: cover; 
}
.right-cards .right-card:nth-child(3) > a {
  background-size: cover;
  background:
     /* linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), */ url("../../assets/img/technology-innovation.png")
    top no-repeat;
    background-size: cover; 
}
.right-cards .right-card:nth-child(4) > a {
  background-size: cover;
  background:
     /* linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), */ url("../../assets/img/consultancy-services.png")
    top no-repeat;
    background-size: cover; 
}


@media (max-width:1523px) {
  .right-cards .right-card .rc-content{
    height: 70%;
  }
}
@media (max-width:1200px) {
  .right-cards .right-card .rc-content{
    height: 80%;
  }
}
@media (max-width:992px) {
  .right-cards .right-card {
    height: 50vh;
  }
  .right-cards .right-card .rc-content{
    height: 50%;
  }
}
@media (max-width:568px) {
  .left-section{
    height: auto; 
  }
  .right-cards .right-card {
    height: 400px;
  }
    .right-cards .right-card .rc-content{
    height: auto;
  }
  .right-cards .right-card:nth-child(4) .rc-content{
    padding-bottom: 4rem;
  }
}