.contact-form iframe {
  height: 500px;
}
.contact-us-logo {
  width: 80px;
  height: 80px;
}

.logo {
  width: 100%;
  height: auto;
  max-width: 100%;
}

.contact-us-icon {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.contact-us-icon svg {
  font-size: 26px;
  color: var(--orange-color);
}

.contact-us a {
  color: #000;
  transition: all ease-in-out 0.3s;
}

.contact-us a:hover {
  color: var(--orange-color);
}

.contact-form a {
  color: #000;
}
.contact-us-link{
  color: var(--orange-color) !important;
  text-decoration: underline !important;
  text-underline-offset: 5px;
}
@media (max-width: 380px) {
  .contact-form iframe {
    height: 550px;
  }
}
