.nav-link{
    margin-right: 1.5rem;
    font-weight: 600 !important;
    font-size: 1rem !important;
}
.inner-nav img{
    width: 120px;
}
.fixed-btns{
    position: fixed;
    bottom: 50px;
    right: 0;
    z-index: 999;
}
.fixed-btns-left{
    right: initial;
    right: 0;
    top: 80px;
}
.fixed-btns li{
    margin-top: 1rem;
    margin-left: .5rem;
}
.fixed-btns li a{
    background: var(--orange-color) !important;
}
.fixed-btns-left .w-icon{
    /* position: fixed;
    top: 100px;
    right: .6rem; */
    /* z-index: 999999; */
    background: #25D366 !important;
    color: #fff !important;
    padding: 13px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    font-size: 1.1rem;
    transition: .3s;
    width: 44px;
    height: 44px;
    margin-right: 0;
}
.fixed-btns-left 
.call-btn{
    background: var(--blue-color) !important;
}

@media (max-width:1300px) {
    .nav-link{
        margin-right: 1rem;
        font-size: 1rem !important;
    }
}
@media (max-width:991px) {
    .nav-link{
        margin-bottom: .7rem;
    }
}