@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  --orange-color: #eb683c;
  --blue-color: #2952a1;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "Raleway", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
a {
  text-decoration: none !important;
}
.default-btn {
  padding: 10px 20px !important;
  background-color: var(--blue-color) !important;
  color: #fff !important;
  border-radius: 5px !important;
  transition: 0.5s !important;
  display: inline-block !important;
}
.default-btn-orange {
  background-color: var(--orange-color) !important;
}
.default-btn:hover {
  background-color: var(--orange-color) !important;
  color: #fff;
}
.main-heading {
  position: relative;
}
.main-heading span {
  color: var(--orange-color);
  position: relative;
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  padding-left: 45px;
  margin-bottom: 5px;
}

.main-heading span.center {
  padding-right: 45px;
}
.main-heading span.center::before {
  position: absolute;
  content: "";
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background: #fc8459;
  width: 30px;
  height: 2px;
}
.main-heading span::after {
  position: absolute;
  content: "";
  left: 0;
  top: 45%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background: #fc8459;
  width: 30px;
  height: 2px;
}

/* .main-heading h2{
  color: var(--blue-color);
  position: relative;
  width: fit-content;
  margin: 0 auto;
  padding: 10px 0;
  text-transform: uppercase;
}
.main-heading h2::before{
  content: "";
  position: absolute;
  top: 5px;
  left: 0;
  width: 50%;
  height: 3px;
  background-color: var(--orange-color);
  border-radius: 5px;
} 
.main-heading h2::after{
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50%;
  height: 3px;
  background-color: var(--orange-color);
  border-radius: 5px;
  
} */
.text-orange {
  color: var(--orange-color) !important;
}
.text-blue {
  color: var(--blue-color) !important;
}
/* .main-heading 
span {
  display: inline-block;
  position: absolute;
  background: var(--orange-color);
  height: 3px;
  left: 50%;
}
.main-heading span.top {
  width: 70px;
  top: 15px;
}
.main-heading span.bottom {
  width: 120px;
  top: 28px;
}
.main-heading span.left {
  transform: translateX(-150px);
}
.main-heading span.bottom.left {
  transform: translateX(-200px);
}
.main-heading span.right {
  transform: translateX(80px);
} */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

.userway_dark,
#userwayAccessibilityIcon {
  position: fixed;
  right: 100% !important;
  left: 3.5% !important;
  bottom: 0%;
  top: 87%;
  background: var(--orange-color) !important;
}

/* test */
/* .goog-te-banner-frame.skiptranslate {
  display: none !important;
}
@media print {
  #google_translate_element {
    display: none;
  }
} */
#google_translate_element {
  position: fixed !important;
  bottom: 50px !important;
  right: 0.5rem !important;
  background-color: var(--blue-color);
  height: 37px;
  overflow: hidden;
  z-index: 999;
  padding: 6px 10px;
  border-radius: 4px;
}
#google_translate_element .VIpgJd-ZVi9od-l4eHX-hSRGPd{
  display: none !important;
}
/* .goog-te-combo{
  display: none !important;
} */
@media (max-width: 568px) {
  .userway_dark,
  #userwayAccessibilityIcon {
    top: 93%;
    left: 15%!important;
  }
  #google_translate_element {
    bottom: 15px !important;
  }
}
