
.card-1,
.card-2,
.card-3 {
    height: 180px;
    width: 90%;
    background: #2952A1 ;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: perspective(600px) rotateY(29deg) skew(0deg);
}
.card-2{
    background: #2951a1cc ;
}
.card-3{
    background: #2853a266  ;
}
.card-1 a,
.card-2 a,
.card-3 a{
    color: #fff;
}
.admission-section p{
    color: #2952a1;
    text-align: center;
    font-weight: 600;
    font-size: 20px;
}
.enquiry-btn{
    position: fixed;
    right: .5rem;
    bottom: 130px;
    z-index: 999;
}